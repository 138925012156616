const translations = {
    en: {
        completed: "✅ Completed",
        continue: "Continue",
        plan_limit_exceeded: "Access to the course is temporarily closed. We sincerely apologize for any inconvenience caused! 🙏😔",
        error_occurred: "An error occurred. Please try again later.",
    },
    ru: {
        completed: "✅ Завершено",
        continue: "Продолжить",
        plan_limit_exceeded: "Доступ к курсу временно закрыт. Мы приносим извинения за причиненные неудобства! 🙏😔",
        error_occurred: "Произошла ошибка. Пожалуйста, попробуйте позже.",
    },
    es: {
        completed: "✅ Completado",
        continue: "Continuar",
        plan_limit_exceeded: "El acceso al curso está temporalmente cerrado. ¡Lamentamos sinceramente cualquier inconveniente! 🙏😔",
        error_occurred: "Ocurrió un error. Por favor, inténtalo de nuevo más tarde.",
    },

    fr: {
        completed: "✅ Terminé",
        continue: "Continuer",
        plan_limit_exceeded: "L'accès au cours est temporairement fermé. Nous nous excusons sincèrement pour tout inconvénient ! 🙏😔",
        error_occurred: "Une erreur est survenue. Veuillez réessayer plus tard.",
    },

    de: {
        completed: "✅ Abgeschlossen",
        continue: "Weiter",
        plan_limit_exceeded: "Der Zugriff auf den Kurs ist vorübergehend gesperrt. Wir entschuldigen uns aufrichtig für die Unannehmlichkeiten! 🙏😔",
        error_occurred: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
    },

    zh: {
        completed: "✅ 已完成",
        continue: "继续",
        plan_limit_exceeded: "课程访问暂时关闭。我们深感抱歉，给您带来不便！🙏😔",
        error_occurred: "发生错误，请稍后重试。",
    },
};

export function translate(languageCode, key) {
    return translations[languageCode]?.[key] || translations.en[key];
}
